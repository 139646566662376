const styles = {
    background: {
        marginBottom: '10vh',
        marginTop: '10vh',
        display: 'flex',
    },
    juror: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20
    },
    imageArea: {
        width: {
            md: '12vw',
            sm: '25vw',
            xs: '42vw'
        }
    },
    name:{
        mt: 2,
        mb: 1
    },
    description: {
        my: 1,
        width: '100%'
    },
    socialMedias: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default styles