const JURORS = [
    {
        name: 'Eduardo Evangelista',
        description: 'Ilustrador, professor universitário, lecionador da matéria de fotografia.',
        facebook: '',
        instagram: 'https://www.instagram.com/edvangelista/',
        website: '',
        image: 'eduardo_evangelista',
        edition: 2021
    },
    {
        name: 'Taís Abel',
        description: 'Turismóloga, fotógrafa profissional há mais de 25 anos, mãe de Pet, ganhadora do concurso em 2019.',
        facebook: '',
        instagram: 'https://www.instagram.com/taisabelfotografia/',
        website: '',
        image: 'tais_abel',
        edition: 2021
    },
    {
        name: 'Lucas Damazio',
        description: 'Publicitário, professor universitário, fotógrafo, pai de pet.',
        facebook: '',
        instagram: 'https://www.instagram.com/lucaspdamazio/',
        website: '',
        image: 'lucas_damazio',
        edition: 2021
    },
    {
        name: 'Joana Adams',
        description: 'Joana Adans é daquelas profissionais que não escolheu a fotografia, mas que foi escolhida por ela. Filha de pai jornalista e de mãe fotógrafa, cresceu no mundo dessa arte e seu primeiro emprego no turno inverso da escola já foi dentro de um estúdio, o que acabou se tornando a grande paixão. Com diversos cursos de fotografia, edição e diagramação e 16 anos de experiência na área, a gaúcha de Torres faz uma bela parceria com a mãe Vana Rodrigues e leva em seu currículo diversos ensaios e eventos, inclusive pela América do Norte e pela Inglaterra. É cachorreira, brincalhona e daquelas que adora o universo de Harry Potter.',
        facebook: 'https://www.facebook.com/joana.adams.5',
        instagram: 'https://www.instagram.com/joanaadams87/',
        website: '',
        image: 'joana_adams',
        edition: 2020
    },
    {
        name: 'Lígia Maciel',
        description: 'Bacharel em Direito, técnica em fotografia pelo SENAC e membro do Inspiration Photohgraphers e Mywed, diretórios que reúnem os melhores profissionais de casamento e de família. Com diversas fotos, casamentos e ensaios premiados entre os melhores do mundo, foi por quatro vezes indicada ao Golden Lens, considerado o Oscar da Fotografia. Além de fotógrafa, é videomaker e responsável por administrar a Ferreira e Maciel Fotografia e Filme. Já participou dos maiores Congressos do Brasil no ramo da fotografia. Adora maquiagem e design de sobrancelhas, é cachorreira (mamãe do Snow), séria, racional e criativa. Mamãe da princesa Helena.',
        facebook: 'https://www.facebook.com/ligiamaciel.foto2',
        instagram: 'https://www.instagram.com/ferreiraemaciel/',
        website: 'http://www.ferreiraemaciel.com.br/',
        image: 'ligia_maciel',
        edition: 2020
    },
    {
        name: 'Miró Machado',
        description: 'Um experiente artista gaúcho moldado pelos 20 anos de experiência e pelos inúmeros cursos na área, inclusive pela UNISINOS. Com prêmios em concursos de fotografia e diretórios que reúnem os melhores do mundo, como o Inspiration Photographers e o Bride Association, é um profissional completo que mostra em suas obras a verdadeira expressão artística de reproduzir em uma pequena fração de segundos o que os olhos não podem ver, misturando nesse universo de luzes, contrastes, cores, pessoas e emoções, um trabalho sincero, à prova do tempo e dos modismos. Realizou casamento em diversos países pela América e Europa e firmou em seu portfólio fotografias poderosas e atemporais. Como bom gaúcho, estende suas habilidades para a churrasqueira e aprecia uma boa cerveja. Gosta de viagens e de retratar os momentos intensos da vida humana.',
        facebook: 'https://www.facebook.com/miro.machado.7',
        instagram: 'https://www.instagram.com/miromachadofotografia/',
        website: 'http://www.miromachado.com.br/',
        image: 'miro_machado',
        edition: 2020
    },
    {
        name: 'Adrielle Peres',
        description: 'Fotógrafa há 9 anos, iniciou na arte desde os 15, talvez por isso a grande paixão do registro dessa fase especial na vida dos adolescentes. Especializada há quatro anos nesse ramo, adora viagens e fotografar pelo mundo, tanto é que já fez fotos de grupos de adolescentes pela Disney, lugar que para ela é um verdadeiro reino encantado. Técnica em administração pelo IFRS, sua fotografia é a expressão do que uma fase da vida representa, comprovando que ainda tem um coração que preserva a melhor etapa vivida e isso é nitidamente percebido na produção de suas obras. A gauchinha, além de fotografar em Torres, diverte-se e curte os bons momentos que só a fotografia pode proporcionar.',
        facebook: 'https://www.facebook.com/adrielle.peres',
        instagram: 'https://www.instagram.com/adrielleperes/',
        website: 'http://www.adrielleperes.com/',
        image: 'adrielle_peres',
        edition: 2019,
    },
    {
        name: 'Vana Rodrigues',
        description: 'Fotógrafa profissional há 20 anos, Vana iniciou com o fotojornalismo em trabalhos para veículos de comunicação, mas as exigências profissionais levaram_na a desenvolver suas habilidades no estúdio. Sua dedicação a vários ramos da fotografia a consolidou no mercado regional, onde recebeu diversas premiações. Antes disso, a arte já se manifestava através de atividades como a pintura. Apaixonada pela luz do entardecer no Parque da Guarita em Torres – RS, fotografa o humano com a natureza, os pássaros, as flores, a luz solar e os desenhos milenares dos rochedos à beira_mar. Em seu estúdio, Vana monta e remonta cenários temáticos, onde os pequenos brincam e soltam a imaginação com a “tia Vana”, que os acompanha desde a barriga das mamães. É mãe, esposa, fotógrafa, artista e apaixonada pelo sorrisos e sapequices dos seus modelinhos.',
        facebook: 'https://www.facebook.com/vana.rodrigues.7',
        instagram: 'https://www.instagram.com/vanarodriguesfotografia/',
        website: '',
        image: 'vana_rodrigues',
        edition: 2019,
    },
    {
        name: 'Willian Mariot',
        description: 'Iniciou sua carreira despretensiosamente em 2014, aceitando o convite para fotografar eventos noturnos e com o tempo, foi aperfeiçoando seu olhar como artista e sua verdadeira identidade. É membro da FineArt Association, Inspiration Photographers e WPJA, associações nas quais recebeu inúmeras premiações internacionais. O fotógrafo de Urussanga trabalha com entusiasmo e interação, buscando criar em sua arte lembranças nostálgicas para quem passa por sua fotografia.',
        facebook: 'https://www.facebook.com/willian.marotfotografia',
        instagram: 'https://www.instagram.com/willianmariotfotografia/',
        website: 'http://www.willianmariot.com.br/',
        image: 'wilian_mariot',
        edition: 2019
    },
    {
        name: 'Felipe Ferreira',
        description: 'Bacharel em Direito, advogado, mestrando da UFSC, palestrante, consultor, gestor de projetos, fotógrafo e videomaker, membro do Inspiration Photographers, colunista de assuntos jurídicos na IPhoto Channel e na EPICS. Palestrou em grandes palcos do cenário nacional, como na Semana da Fotografia. Foi professor universitário de fotografia e comunicação. Escreveu "Fotografia, edição e diagramação de imagens" pela Editora UNOESC. Apresentou o Oscar da Fotografia, o Golden Lens em 2018. É idealizador do Fotografia é o Meu Negócio, que ensina assuntos jurídicos e de empreendedorismo aos profissionais da imagem. Rabisca umas poesias, toca violão, pratica trekking e montanhismo, ama a natureza. É extremamente sonhador. Apaixonado por cinema. Tem um “ursinho” chamado Snow. Já foi atleta de Handebol e adora assar um churrasco, ama chimarrão! Marido da fotógrafa Lígia e papai da princesa Helena.',
        facebook: 'https://www.facebook.com/felipe.ferreira.180',
        instagram: 'https://www.instagram.com/ferreiraemaciel/',
        website: 'http://www.ferreiraemaciel.com.br/',
        image: 'felipe_ferreira',
        edition: 2018
    },
    {
        name: 'Lígia Maciel',
        description: 'Bacharel em Direito, técnica em fotografia pelo SENAC e membro do Inspiration Photohgraphers e Mywed, diretórios que reúnem os melhores profissionais de casamento e de família. Com diversas fotos, casamentos e ensaios premiados entre os melhores do mundo, foi por quatro vezes indicada ao Golden Lens, considerado o Oscar da Fotografia. Além de fotógrafa, é videomaker e responsável por administrar a Ferreira e Maciel Fotografia e Filme. Já participou dos maiores Congressos do Brasil no ramo da fotografia. Adora maquiagem e design de sobrancelhas, é cachorreira (mamãe do Snow), séria, racional e criativa. Mamãe da princesa Helena.',
        facebook: 'https://www.facebook.com/ligiamaciel.foto2',
        instagram: 'https://www.instagram.com/ferreiraemaciel/',
        website: 'http://www.ferreiraemaciel.com.br/',
        image: 'ligia_maciel',
        edition: 2018
    },
    {
        name: 'Miró Machado',
        description: 'Um experiente artista gaúcho moldado pelos 20 anos de experiência e pelos inúmeros cursos na área, inclusive pela UNISINOS. Com prêmios em concursos de fotografia e diretórios que reúnem os melhores do mundo, como o Inspiration Photographers e o Bride Association, é um profissional completo que mostra em suas obras a verdadeira expressão artística de reproduzir em uma pequena fração de segundos o que os olhos não podem ver, misturando nesse universo de luzes, contrastes, cores, pessoas e emoções, um trabalho sincero, à prova do tempo e dos modismos. Realizou casamento em diversos países pela América e Europa e firmou em seu portfólio fotografias poderosas e atemporais. Como bom gaúcho, estende suas habilidades para a churrasqueira e aprecia uma boa cerveja. Gosta de viagens e de retratar os momentos intensos da vida humana.',
        facebook: 'https://www.facebook.com/miro.machado.7',
        instagram: 'https://www.instagram.com/miromachadofotografia/',
        website: 'http://www.miromachado.com.br/',
        image: 'miro_machado',
        edition: 2018
    },
    {
        name: 'Rosi Silva',
        description: 'Filha de fotógrafo, Rosilene Silva da Rosa aprendeu desde muito cedo o amor pela profissão. Fotógrafa há mais de 15 anos, Rosi busca fazer da sua arte uma verdadeira expressão do que é o amor, desde a geração da vida na barriga das mamães até a união de dois apaixonados durante um casamento, fazendo com que os momentos únicos desse sentimento possam ser concebidos em suas imagens. Mãe e esposa, Rosi também dedica_se a fotografia de estúdio na cidade de Turvo – SC.',
        facebook: 'https://www.facebook.com/rosi.dalpont',
        instagram: '',
        website: '',
        image: 'rosi_silva',
        edition: 2018
    }
]

export default JURORS