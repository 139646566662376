import React, { useState, useEffect } from 'react'
import { Layout } from '@components/organisms'
import { PageHeader } from '@components/molecules'
import { Tabs } from '@components/atoms'
import EDITIONS from '@constants/editions'
import { JurorsList } from '@pagesComponents/jurors'

const editionsArr = Object.values(EDITIONS).reverse()

const Jurors = props => {
    const [edition, setEdition] = useState(0)

    useEffect(() => {
        if (props.location?.state?.edition) setEdition(editionsArr.findIndex(el => el.id === props.location.state.edition))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout seo={{ title: 'Jurados', description: 'Jurados do Concurso de Fotografia - Foco no Cooperativismo do Sicoob Credisulca' }}> 
            <PageHeader icon={{ name: 'libra', alt: 'Icone balança' }} description='Conheça os especialistas na arte da fotografia responsáveis
                pela escolha das obras que melhor representam a sustentabilidade'
            >
                Conheça os jurados
            </PageHeader>
            <Tabs selected={ edition } onChange={ key => setEdition(key) } tabs={ editionsArr.map(el => ({ caption: el.id, label: `${ el.index }ª edição` })) }/>
            <JurorsList edition={ editionsArr[edition].id }/>
        </Layout>
    )
}

export default Jurors