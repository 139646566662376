import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import styles from './styles'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from '@mui/material/Button'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import IconButton from '@mui/material/IconButton'
import LanguageIcon from '@mui/icons-material/Language'
import Box from '@mui/material/Box'

const SocialMedia = ({ name, href, icon }) => href && (
    <IconButton aria-label={ `Abrir ${ name } do jurado` } target="_blank" color='primary' href={ href } style={{ padding: 5 }} rel="noopener">
        { icon }
    </IconButton>
)

const JurorInfo = props => {
    const { image, name, description, facebook, instagram, website } = props
    const [showAll, setShowAll] = useState(false)
    const subDescription = description.substr(0, description.length < 300 ? 300 : description.slice(0, 300).lastIndexOf(" "))

    return (
        <div id={ `${ image.name.split('.')[0] }` } style={ styles.juror } itemScope itemType="http://schema.org/Person">
            <Box sx={ styles.imageArea }>
                <GatsbyImage image={ getImage(image) } alt={ `Jurado(a) ${ name }` } itemProp='image' />
            </Box>
            <Typography color='primary' variant='h6' align='center' sx={ styles.name } itemProp='name'>
                { name }
            </Typography>
            <div styles={ styles.socialMedias }>
                <SocialMedia name='facebook' href={ facebook } icon={ <FacebookIcon fontSize='small'/> }/>
                <SocialMedia name='instagram' href={ instagram } icon={ <InstagramIcon fontSize='small'/> }/>
                <SocialMedia name='site' href={ website } icon={ <LanguageIcon fontSize='small'/> }/>
            </div>
            <Typography sx={ styles.description } color='secondary' variant='body2' align='center' itemProp='description'>
                {  showAll ? description : subDescription + (description.length > subDescription.length ? '...' : '') }
            </Typography>
            { description.length > subDescription.length &&
                <Button variant='outlined' size='small' onClick={ () => setShowAll(!showAll) }>
                    { showAll ? 'Esconder' : 'Mostrar tudo' }
                </Button>
            }
        </div> 
    )
}
export default JurorInfo