import React from 'react'
import Grid from '@mui/material/Grid'
import styles from './styles'
import JURORS from '@constants/jurors'
import useFolderImages from '@hooks/useFolderImages'
import Juror from './Juror'
import Container from '@mui/material/Container'
import { Feedback } from '@components/molecules'

const JurorsList = props => {
    const { edition } = props
    const data = JURORS.filter(juror => juror.edition === Number(edition))
    const jurorsImages = useFolderImages('jurors')
    
    if (data.length === 0) return <Feedback description='Ainda não definimos os jurados desta edição, mas fique ligado, em breve eles serão apresentados aqui'/>
    return (
        <Container maxWidth='lg' sx={ styles.background }>
            <Grid container spacing={ 3 } justifyContent='center'>
                { data.map(juror => {
                    const imageData = jurorsImages.find(({ name }) => name === juror.image)

                    return (
                        <Grid item md={ 4 } sm={ 6 } xs={ 12 } key={ juror.image } >
                            <Juror { ...juror } image={ imageData }/>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default JurorsList